/*!

 =========================================================
 * Material Dashboard Angular - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-angular2
 * Copyright 2018 Creative Tim (http://www.creative-tim.com)

 * Designed by www.invisionapp.com Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

 */

 
//@import '~css-star-rating/scss/star-rating';

@import "@angular/material/prebuilt-themes/indigo-pink.css";

@import "core/variables";
@import "core/mixins";
@import "../../../node_modules/bootstrap/scss/bootstrap";

// Core Components
@import "core/buttons";
@import "core/checkboxes";
@import "core/radios";
@import "core/forms";
@import "core/input-group";
@import "core/images";
@import "core/navbar";
@import "core/alerts";
@import "core/type";
@import "core/tabs";
@import "core/tooltip";
@import "core/popover";
@import "core/dropdown";
@import "core/togglebutton";
@import "core/ripples";
@import "core/footers";
@import "core/sidebar-and-main-panel";
@import "core/fixed-plugin";
@import "core/tables";
@import "core/misc";

@import "core/cards";
@import "core/cards/card-stats";
@import "core/cards/card-profile";
@import "core/cards/card-plain";

//plugin scss
@import "core/plugins/animate";
@import "core/plugins/chartist";
@import "core/plugins/perfect-scrollbar";

@import "core/responsive";

@import "../../../node_modules/ag-grid-enterprise/styles/ag-grid.css";
@import "../../../node_modules/ag-grid-enterprise/styles/ag-theme-alpine.css";


.card-body {
  overflow: auto;
}

tr,
td,
th {
  padding: 2px !important;
  margin: 0px !important;
  min-width: 0;
}

.mat-dialog-container {
  background-color: transparent;
  box-shadow: none !important;
}

.cdk-overlay-pane {
  max-width: 100% !important;
  width: 90% !important;
}

.tab1 table td + td + td + td {
  background-color: #bbbbff !important;
}

.tab1 table td + td + td + td + td {
  background-color: transparent !important;
}

.tab1 table td + td + td + td + td + td + td {
  background-color: #bbbbff !important;
}

.tab1 table td + td + td + td + td + td + td + td {
  background-color: transparent !important;
}

.main-panel-full {
  width: 100%;
}

.mat-tab-body-content {
  overflow-x: hidden !important;
}

/* grid lines */

.grid table > tbody > tr > td {
  box-sizing: border-box !important;
  border-right: 1px solid rgba(117, 117, 117, 0.288) !important;
  text-align: center !important;

  padding-left: 2px;
  padding-right: 2px;

  white-space: nowrap !important;
}

.grid tbody > tr:nth-child(odd) {
  background-color: #f0f0ff;
  color: rgb(0, 0, 0);
}

.grid table > thead > tr > th {
  //  box-sizing: border-box !important;
  // border-right: 2px solid rgba(160, 159, 159, 0.5) !important;
  text-align: center !important;
  // background-color: rgba(132, 184, 236, 0.123) !important;
}

.grid table > tbody {
  box-sizing: border-box !important;
  //border-top: 2px solid rgb(160, 159, 159) !important;
  text-align: center !important;
}

.grid table > tbody > tr.selected {
  background-color: rgba(148, 177, 206, 0.575) !important;
}



/////////////////////////

::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); 
  box-shadow: inset 0 0 6px rgba(0,0,0,0.3); 
  -webkit-border-radius: 10px;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: lightgray;
  --webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5);
  box-shadow: inset 0 0 6px rgba(0,0,0,0.5); 
}

